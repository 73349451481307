export function assertIsDefined<T>(val: T | undefined, expectedMessage?: string): asserts val is T {
  if (val === undefined) {
    throw new Error(expectedMessage || `Expected value to be defined.`);
  }
}

export function assertNotNull<T>(val: T | null, expectedMessage?: string): asserts val is T {
  if (val === null) {
    throw new Error(expectedMessage || `Expected value to be not null.`);
  }
}
