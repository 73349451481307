import { CSVSourceType, DataImport, DataSource, DataSourceType } from "@coeff/api";

import {
  DATA_SOURCES_WITH_MULTIPLE_CONNECT_MODES,
  MULTIPLE_OAUTH_DATASOURCES,
  OAUTH_DATASOURCES,
} from "./constants";

/* eslint-disable @typescript-eslint/no-unsafe-call */
export const getMaskedConnectionString = (connectionStr: string | undefined): string => {
  try {
    if (connectionStr === undefined) {
      return "*****";
    }
    if (connectionStr.includes("jdbc:databricks")) {
      const passwordToMask = connectionStr.substring(connectionStr.lastIndexOf("PWD=") + 4);

      // @ts-ignore
      return connectionStr.replaceAll(passwordToMask, "*****") as string;
    }
    if (!String(connectionStr).includes("mongodb+srv")) {
      return String(connectionStr);
    }
    // @ts-ignore
    const usernamePass = connectionStr.match(/mongodb\+srv:\/\/([^@]+)/)[1];
    const passwordToMask = usernamePass.substring(usernamePass.lastIndexOf(":") + 1);
    // @ts-ignore
    return connectionStr.replaceAll(passwordToMask, "*****") as string;
  } catch (error) {
    return "*****";
  }
};

export enum TunnelAuthMethod {
  PrivateKey = "Private Key",
  Password = "Password",
}

export type TunnelConfig = {
  tunnel_host: string;
  tunnel_port: number;
  tunnel_username: string;
  tunnel_auth_method?: TunnelAuthMethod;
  tunnel_private_key?: string;
  tunnel_passphrase?: string;
  tunnel_password?: string;
  use_tunnel: boolean;
};

export const extractTunnelConfig = (dataSource?: DataSource): TunnelConfig => {
  return {
    use_tunnel: Boolean(dataSource?.credentials?.use_tunnel),
    tunnel_host: dataSource?.credentials?.tunnel_host ?? "",
    tunnel_port: dataSource?.credentials?.tunnel_port ?? 22,
    tunnel_username: dataSource?.credentials?.tunnel_username ?? "",
    tunnel_private_key: dataSource?.credentials?.tunnel_private_key ?? "",
    tunnel_auth_method:
      (dataSource?.credentials?.tunnel_auth_method as TunnelAuthMethod) ??
      TunnelAuthMethod.PrivateKey,
    tunnel_passphrase: dataSource?.credentials?.tunnel_passphrase ?? "",
    tunnel_password: dataSource?.credentials?.tunnel_password ?? "",
  };
};

export const isGoogleDriveDataImport = (dataImport?: DataImport) => {
  return (
    (dataImport?.data_source_type === DataSourceType.Csv &&
      dataImport.source_info &&
      dataImport.source_info.csv_source_type !== CSVSourceType.Url) ||
    dataImport?.data_source_type === DataSourceType.GoogleSheets
  );
};

export const isOneDriveDataImport = (dataImport?: DataImport) => {
  return (
    dataImport?.data_source_type === DataSourceType.Excel &&
    dataImport.source_info?.excel_source_type === DataSourceType.OneDrive
  );
};

export const getResolvedDataSourceType = (
  dataImport: DataImport | undefined,
  dataSource: DataSource | undefined
): DataSourceType => {
  return (
    isGoogleDriveDataImport(dataImport)
      ? DataSourceType.GoogleDrive
      : isOneDriveDataImport(dataImport)
      ? DataSourceType.OneDrive
      : dataSource?.data_source_type ?? dataImport?.data_source_type
  ) as DataSourceType;
};

export const isOAuthDataSourceConnection = (params: {
  // Some imports don't have an assigned data_source_id, or the data source may
  // have been deleted. E.g. google_sheets, csv, one_drive. For these, rely on
  // dataImport.data_source_type to resolve the underlying data source type.
  dataSource: DataSource | undefined;
  dataImport: DataImport | undefined;
}): boolean => {
  const { dataSource, dataImport } = params;

  if (
    dataSource &&
    DATA_SOURCES_WITH_MULTIPLE_CONNECT_MODES.includes(dataSource.data_source_type)
  ) {
    // The logic to determine if the data source is OAuth can differ based on the data source type
    if (dataSource.data_source_type === DataSourceType.Snowflake) {
      return dataSource.ppress_metadata?.client_id !== undefined;
    } else if (dataSource.data_source_type === DataSourceType.Bigquery) {
      return !dataSource.credentials.client_key_json;
    } else if (dataSource.data_source_type === DataSourceType.Looker) {
      return dataSource.ppress_metadata?.client_app_guid !== undefined;
    }
  }

  const resolvedDataSourceType = getResolvedDataSourceType(dataImport, dataSource);

  return (
    MULTIPLE_OAUTH_DATASOURCES.includes(resolvedDataSourceType) ||
    OAUTH_DATASOURCES.includes(resolvedDataSourceType)
  );
};

export const getLookerBaseUrl = (url: string) =>
  url?.replace(/^https?:\/\//, "").replace(/(:\d+)?\/?$/, "");
