import { notification } from "antd";

interface NotifyProps {
  description: string;
  duration?: number | null;
  message: string;
  onClick?: () => void;
  onClose?: () => void;
  type: "success" | "info" | "warning" | "error";
}

/* destroys all notifications */
export const denotify = () => notification.destroy();

/* creates a notification */
export const notify = ({ type, ...rest }: NotifyProps) =>
  notification[type]({
    ...rest,
  });
