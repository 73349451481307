import { Config } from ".";

const BASE_URL = "https://dev.coefficient.io";

export const staging: Config = {
  BASE_URL,
  SENTRY_DSN: "https://5b95e62434504b42889c10f4d2582b8c@o404721.ingest.sentry.io/5269254",
  GOOGLE_CLIENT_ID: "835967182188-i7lrd1egai2p5808eg30b16s8qplr4ko.apps.googleusercontent.com",
  GOOGLE_DEVELOPER_API_KEY: "AIzaSyCjllJaU3sWjato8ZsKIyW5DNPXe_cOepI",
  SALESFORCE_CONNECTED_APP_CONSUMER_KEY:
    "3MVG9LBJLApeX_PCzOK2ZVrcYiu4V9IYVWKbE1uucDYLV5cnXgABVMA3kal4fPRWVWcVHbv1V4u2Is7KD2oQQ",
  STRIPE_API_KEY:
    "pk_test_51IK1n5GLbIh3iVB0e3vDaxRkslgE3ab7oEcOcsdfATCa8NjBMSUU58Gwdezx1UYG5uvDz9sGz7rgfNVEaP585aj200zBEPNhPJ",
  OAUTH_REDIRECT_URL_BASE: "https://dev.coefficient.io/api/oauth2_proxy",
  MS_IDP_CLIENT_ID: "1e1c1f7e-58dd-4521-952a-c7c310fd5752",
  MS_UI_ENDPOINT: "https://d2caj5pystcwak.cloudfront.net/staging",
  SNOWFLAKE_SECURITY_INTEGRATION_NAME: "COEFFICIENT_STAGING",
  ZENDESK_CHAT_KEY: "ece9a193-78ba-4042-a625-9026480535d0",
  ZENDESK_MESSENGER_KEY: "a36735de-b6ec-4be7-a806-0fd3192c4534",
};
