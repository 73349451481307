import { History } from "history";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { DEPRECATED_ApiManager } from "../api";
import { AppState } from "../store";

export * from "./extAdmin";
export * from "./app";
export * from "./auth";
export * from "./billing";
export * from "./coeffAdmin";
export * from "./dashboard";

export type AppThunkExtraArgs = { api: DEPRECATED_ApiManager; history: History };

export type AppThunkAction<R> = ThunkAction<R, AppState, AppThunkExtraArgs, Action>;

export type AppThunkDispatch = ThunkDispatch<AppState, AppThunkExtraArgs, Action>;
